.background-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 5000;
    overflow: hidden;
  }
  .loader {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -300px;
    width: 300px;
    height: 600px;
    background: url("./loader.png") center center no-repeat;
    background-size: contain;
    transform: rotateY(0deg);
    animation: rotateAnimation 2s linear infinite;
  }

  @keyframes rotateAnimation {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }