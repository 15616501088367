.list-container {
    display: flex;
    font-size: 18px;
    background-color: #eee;
    flex-direction: column;
}

.item-container {
    background-color: #fff;
    padding: 0px 35px;
    margin: 15px 50px;
}

.item-container .card-header {
    margin: 0px -35px;
}