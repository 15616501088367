ul.checkbox-image {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  li {
    width: 100%;
    max-width: 25%;
    @media (max-width: 1700px) {
        max-width: 33%;
    }

    @media (max-width: 1400px) {
        max-width: 50%;
    }

    @media (max-width: 1100px) {
        max-width: 100%;
    }

    @media (max-width: 991px) {
        max-width: 20%;
    }

    @media (max-width: 700px) {
        max-width: 25%;
    }

    @media (max-width: 490px) {
        max-width: 33%;
    }
  }

  input[type="checkbox"][id^="cb"] {
    display: none;
  }

  label {
    border: 1px solid #fff;
    padding: 10px;
    display: block;
    position: relative;
    margin: 5px;
    cursor: pointer;
  }

  label:before {
    background-color: white;
    color: white;
    content: " ";
    display: block;
    border-radius: 50%;
    border: 1px solid grey;
    position: absolute;
    top: -6px;
    left: -5px;
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 28px;
    transition-duration: 0.4s;
    transform: scale(0);
  }

  label img {
    width: 100%;
    transition-duration: 0.2s;
    transform-origin: 50% 50%;
  }

  :checked + label {
    border-color: #11cdef;
  }

  :checked + label:before {
    content: "✓";
    background-color: #11cdef;
    transform: scale(1);
    border: none;
    z-index: 1;
  }

  :checked + label img {
    transform: scale(0.95);
    box-shadow: 0 0 5px #333;
    z-index: -1;
  }
}
